import * as React from 'react'

class PhotoViewer extends React.Component {
    onClick = (e) => {
        if (this.props.onClick) this.props.onClick(e);
    };

    get photos() {
        return (
            <div className="photo-viewer" style={{overflowX: 'auto', whiteSpace: 'nowrap'}}>
                {this.props.photos.map((p, i) => {
                    const style = {
                        height: p.thumb.height,
                        width:  p.thumb.width,
                        ...defaultStyle,
                        ...(this.props.style || {})
                    };

                    return (
                        <div className="photo-viewer-image" key={i} data-index={i} onClick={this.onClick} style={style}>
                            <aside>{this.props.customControls}</aside>
                            <img src={p.thumb.url} alt={p.description} style={{width: '100%'}}/>
                        </div>
                    )
                })}
            </div>
        )
    }

    get placeholder() {
        return this.props.placeholder || (
            <div className="text-black-light" style={{ textAlign: 'center', margin: '48px'}}>
                <i className="fa fa-camera-retro fa-4x"/>
                <div style={{margin: '12px'}}>No photos available</div>
            </div>
        );
    }

    render() {
        const { photos = [] } = this.props;

        return (photos.length > 0) ? this.photos : this.placeholder;
    }
}

const defaultStyle = {
    display:    'inline-block',
    position:   'relative',
    margin:     '14px 12px 12px 0',
    cursor:     'pointer'
};

export default PhotoViewer