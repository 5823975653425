import * as React       from 'react';
import ReactImages      from 'react-images';
import PhotoViewer      from './PhotoViewer';

class Lightbox extends React.Component {
    constructor() {
        super();
        this.state = {
            lightboxOpen:   false,
            currentImage:   0
        };
    }

    openLightbox = (idx = 0) => {
        this.setState({ currentImage: parseInt(idx), lightboxOpen: true });
    };

    closeLightbox = () => {
        this.setState({ lightboxOpen: false });
    };

    gotoNextImage = () => {
        this.setState({ currentImage: this.state.currentImage + 1 });
    };

    gotoPrevImage = () => {
        this.setState({ currentImage: this.state.currentImage - 1});
    };

    get lightbox() {
        if (this.props.photos.length > 0) {
            const { lightboxOpen, currentImage } = this.state;

            return (
                <ReactImages
                    images={this.images}
                    isOpen={lightboxOpen}
                    currentImage={currentImage}
                    onClickPrev={this.gotoPrevImage}
                    onClickNext={this.gotoNextImage}
                    onClose={this.closeLightbox}
                    backdropClosesModal={true}
                    customControls={[this.downloadButton]}
                />
            );
        }
    }

    get images() {
        return this.props.photos.map(p => (
            { src: p.original.url, caption: p.description }
        ));
    }

    get downloadButton() {
        const { currentImage }  = this.state,
            downloadUrl         = this.props.photos[currentImage].original.url;

        return (
            <a key='download' href={downloadUrl} target="_blank" download>
                <i className="fa fa-lg fa-download" style={{color: 'white', margin: '12px 0 0 4px'}}/>
            </a>
        );
    }

    render() {
        return (
            <div>
                {this.lightbox}
                <PhotoViewer photos={this.props.photos} onClick={(e) => this.openLightbox(e.currentTarget.dataset.index)}/>
            </div>
        );
    }
}

export default Lightbox