import * as React   from 'react';
import Dropzone     from 'react-dropzone';
import PhotoViewer  from './PhotoViewer';
import Filename     from 'react-fine-uploader/filename';
import Filesize     from 'react-fine-uploader/filesize';
import ProgressBar  from 'react-fine-uploader/progress-bar';
import Status       from 'react-fine-uploader/status';

class PhotoManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submittedFiles: []
        };
    }

    componentDidMount() {
        this.props.uploader.on('submitted', this.onSubmitted);
    }

    componentWillUnmount() {
        this.props.uploader.off('submitted', this.onSubmitted);
    }

    onSubmitted = (id) => {
        const submittedFiles = this.state.submittedFiles;
        submittedFiles.push(id);

        this.setState({ submittedFiles });
    };

    onDrop(params, files) {
        this.props.uploader.methods.addFiles(files, params, window.location.pathname + '/photos');
    }

    onImageClick(source, e) {
        if (e.target.tagName === 'I') {
            const idx   = e.currentTarget.dataset.index,
                  photo = this.props[`${source}Photos`][idx];

            this.props.onDelete(photo.id);
        }
    }

    get uploads() {
        const { submittedFiles }    = this.state,
              { uploader }          = this.props;

        if (submittedFiles.length > 0) {
            return (
                <div style={{ padding: 12 }}>
                    <h5>Uploads</h5>
                    <table className="table-minimal">
                        <thead>
                            <tr>
                                <th>File name</th>
                                <th>File size</th>
                                <th>Public</th>
                                <th>Progress</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody className="tbody-minimal">
                            {
                                submittedFiles.map(id => (
                                    <tr>
                                        <td><Filename id={id} uploader={uploader}/></td>
                                        <td><Filesize id={id} uploader={uploader}/></td>
                                        <td>{uploader.methods._paramsStore.get(id).public.toString()}</td>
                                        <td><ProgressBar id={id} uploader={uploader} hideBeforeStart={false} hideOnComplete={false}/></td>
                                        <td><Status id={id} uploader={uploader}/></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            )
        }
    }

    get customControls() {
        return (
            <span className="fa-stack" style={{margin: 3, cursor: 'pointer'}}>
                <i className="fa fa-circle fa-stack-2x" style={{color: 'red'}}/>
                <i className="fa fa-trash-o fa-inverse fa-stack-1x" style={{marginTop: '-1px'}}/>
            </span>
        )
    }

    render() {
        return (
            <div className="photo-manager">
                <div style={{overflow: 'auto', paddingRight: 12}}>
                    <Dropzone className="dropzone" accept={"image/jpeg,image/png"} onDrop={this.onDrop.bind(this, { public: true })}>
                        <div className="content">Drop Public Photos</div>
                    </Dropzone>
                    <PhotoViewer photos={this.props.publicPhotos} customControls={this.customControls} style={{cursor: 'auto'}}
                                 onClick={(e) => this.onImageClick('public', e)}/>
                </div>
                <div style={{overflow: 'auto', paddingRight: 12}}>
                    <Dropzone className="dropzone" accept={"image/jpeg,image/png"} onDrop={this.onDrop.bind(this, { public: false })}>
                        <div className="content">Drop Private Photos</div>
                    </Dropzone>
                    <PhotoViewer photos={this.props.privatePhotos} customControls={this.customControls} style={{cursor: 'auto'}}
                                 onClick={(e) => this.onImageClick('private', e)}/>
                </div>
                {this.uploads}
            </div>
        )
    }
}

export default PhotoManager