import * as React       from 'react';
import { createPortal } from 'react-dom';

class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.root   = document.getElementById('modal');
        this.el     = document.createElement('div');
    }

    componentDidMount() {
        this.root.appendChild(this.el);
    }

    componentWillUnmount() {
        this.root.removeChild(this.el);
    }

    handleClose = () => {
        if (this.props.handleClose) this.props.handleClose();
    };

    render() {
        return createPortal(
            <React.Fragment>
                <div className="modal-content">{this.props.children}</div>
                <div className="modal-overlay" onClick={this.handleClose}/>
            </React.Fragment>,
            this.el
        );
    }
}

export default Modal;