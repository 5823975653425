import * as React               from 'react';
import Lightbox                 from './Lightbox';
import PhotoManager             from './PhotoManager';
import Modal                    from './Modal';
import FineUploaderTraditional  from 'fine-uploader-wrappers';

class PhotoAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state      = { editingPhotos: false, photos: this.props.photos };
        this.uploader   = new FineUploaderTraditional({
            options: {
                autoUpload:     true,
                maxConnections: 1
            }
        });
    }

    get publicPhotos() {
        const { photos = [] } = this.state;

        return photos.filter(p => p.public === true);
    }

    get privatePhotos() {
        const { photos = [] } = this.state;

        return photos.filter(p => p.public !== true);
    }

    get modal() {
        if (this.state.editingPhotos === true) {
            return (
                <Modal handleClose={() => this.setState({ editingPhotos: false })}>
                    <PhotoManager publicPhotos={this.publicPhotos} privatePhotos={this.privatePhotos}
                        uploader={this.uploader} onDelete={this.handleDelete}/>
                </Modal>
            )
        }
    }

    componentDidMount() {
        this.uploader.on('complete', this.handleComplete);
    }

    componentWillUnmount() {
        this.uploader.off('complete', this.handleComplete);
    }

    handleComplete = (id, name, resp) => {
        if (resp.success === true && typeof resp.photo !== 'undefined') {
            const { photos = [] } = this.state;
            photos.push(resp.photo);

            this.setState({ photos })
        }
    };

    handleDelete = (id) => {
        const path = `${window.location.pathname}/photos/${id}`;

        fetch(path, { method: 'delete' }).then((resp) => {
            if (resp.status >= 200 && resp.status < 300) {
                const photos = this.state.photos.filter(p => p.id !== id);
                this.setState({ photos });
            }
        });
    };

    render() {
        return (
            <React.Fragment>
                <Lightbox photos={this.state.photos}></Lightbox>
                <small className="pull-right"><a onClick={() => this.setState({ editingPhotos: true })}>Manage Photos</a></small>
                {this.modal}
            </React.Fragment>
        )
    }
}

export default PhotoAdmin